<template>
    <div class="fill full-height-layout">
        <supplier-orderlines :is-virtual-supplier-orderline-overview="true" :order-line-overview-type="orderLineOverviewType"></supplier-orderlines>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import SupplierOrderlines from '../supplier/SupplierOrderlines'

export default {
    name: 'OrderListOverview',
    components: { SupplierOrderlines },
    mixins: [validationMixin],
    props: {
        orderLineOverviewType: {
            type: String,
            default: 'orders',
        },
    },
}

</script>
